<template>
	<div class="textKR">
		<div class="text" :style="{fontSize:fontSize+'px'}">
			<slot />
			<div class="text2" :style="{fontSize:fontSize+'px'}" ref="text">
				<slot />
			</div>
			<h4 :style="{marginLeft:x+'px', fontSize:(krFontSize)+'px', top: krTopOffset+'px'}" ref="kr">
				{{ kr }}
			</h4>
		</div>
	</div>
</template>

<script>
import { Expo, gsap, Quad } from 'gsap/all'

export default {
	name: 'TextKR',
	props: {
		kr: {
			type: String,
			default: ''
		},
		x: {
			type: Number,
			default: 0
		},
		size: {
			type: String,
			default: 'normal'
		}
	},
	computed: {
		fontSize () {
			switch (this.size) {
			default:
			case 'normal':
				return 54
			case 'small':
				return 20
			}
		},
		krFontSize () {
			switch (this.size) {
			default:
			case 'normal':
				return 35
			case 'small':
				return 13
			}
		},
		krTopOffset () {
			switch (this.size) {
			default:
			case 'normal':
				return -65
			case 'small':
				return -25
			}
		}
	},
	mounted () {
		this.show()
	},
	methods: {
		show () {
			gsap.fromTo(this.$refs.text, { x: -70, opacity: 0 }, { delay: 0.2, ease: Expo.easeOut, duration: 0.8, x: 0, opacity: 1 })
			gsap.fromTo(this.$refs.kr, { x: -70, opacity: 0 }, { delay: 0.1, ease: Expo.easeOut, duration: 0.8, x: 0, opacity: 1 })
		},
		hide () {
			gsap.to(this.$refs.text, { x: 90, opacity: 0, delay: 0.1, ease: Quad.easeOut, duration: 0.5 })
			gsap.to(this.$refs.kr, { x: 70, opacity: 0, delay: 0.1, ease: Quad.easeOut, duration: 0.5 })
		}
	}
}
</script>

<style lang="stylus" scoped>
.textKR
	width auto
	user-select none
	font-family: NanumGothic;
	font-size: 54px;
	font-style: normal;
	font-weight: 700;
	line-height: 54px;
	letter-spacing: -0.02em;
	.text
		position relative
		display inline-block
		color transparent
	.text2
		will-change transform, opacity
		position absolute
		color #000
		top 0
		left 0
		z-index 2
	h4
		position absolute
		width 100%
		top -65px
		left 0
		z-index 0
		will-change transform, opacity
		user-select none
		color transparent
		font-size 35px
		font-weight: 500
		background : linear-gradient(232.78deg, #FF5379 30.84%, rgba(255, 83, 121, 0.5) 76.68%)
		background-clip: text
		text-align center
</style>
