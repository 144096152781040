import { ref } from 'vue'

const savedProperties = ['name', 'email', 'register', 'chapter', 'chaptersUnlock', 'stagesUnlock', 'lastStageFinish', 'lastChapterFinish']

class UserData {
	constructor () {
		this.name = ref('Bob')
		this.email = ref('bob@bob.com')
		this.register = ref(true)
		this.isLogin = ref(false)
		this.chapter = ref('normal')
		this.chaptersUnlock = ref(['hangul'])
		this.stagesUnlock = ref([])
		this.stagesScore = {}
		this.lastStageFinish = ref('none')
		this.lastChapterFinish = ref('none')
		this.resetTest()
		this.save()
		this.load()
	}

	// ----------------------------------------------------------------------------- SAVE & LOAD

	save () {
		const json = {}
		for (const p of savedProperties) {
			const v = this[p].value
			if (typeof (v) === 'object') {
				const arr = []
				for (let i = 0; i < v.length; i++) {
					arr[i] = v[i]
				}
				json[p] = arr
			} else {
				json[p] = v
			}
		}
		json.stagesScore = {}
		for (const key in this.stagesScore) {
			json.stagesScore[key] = this.stagesScore[key]
		}
		localStorage.setItem('userSaveKimbapp', JSON.stringify(json))
		return JSON.stringify(json)
	}

	load () {
		let json = localStorage.getItem('userSaveKimbapp')
		if (json == null) {
			console.log('no user saved')
		}
		json = JSON.parse(json)
		for (const key in json) {
			if (key == 'stagesScore') {
				this.stagesScore = {}
				for (const key2 in json[key]) {
					this.stagesScore[key2] = json[key][key2]
				}
			} else if (this[key]) {
				this[key].value = json[key]
			}
		}
	}

	resetTest () {
		this.chaptersUnlock.value = ['hangul']
		this.stagesUnlock.value = ['hangul01', 'hangul02', 'hangul03']
		this.stagesScore = { hangul01: 3, hangul02: 2 }
		this.lastStageFinish.value = 'hangul02'
		// this.lastChapterFinish.value = 'none'
	}

	getScore (stageID) {
		return this.stagesScore[stageID] ? this.stagesScore[stageID] : 0
	}

	saveScore (stageID, score) {
		this.stagesScore[stageID] = score
		this.save()
	}

	// -------------------------------------------------------------------------------- UNLOCK chapters & Stages
	unlockChapter (chapterID) {
		for (const chapter of this.chaptersUnlock.value) {
			if (chapterID == chapter) {
				this.lastChapterFinish.value = 'none'
				console.log('chapter already completed')
				return false
			}
		}
		this.chaptersUnlock.value.push(chapterID)
		this.lastChapterFinish.value = 'none'
		this.save()
		return true
	}

	unlockStage (stageID) {
		for (const stage of this.stagesUnlock.value) {
			if (stageID == stage) {
				this.lastStageFinish.value = 'none'
				console.log('stage already completed')
				return false
			}
		}
		this.stagesUnlock.value.push(stageID)
		this.lastStageFinish.value = 'none'
		this.save()
		return true
	}

	finishStage (stageID) {
		this.lastStageFinish.value = stageID
		this.save()
	}

	finishChapter (chapterID) {
		this.lastChapterFinish.value = chapterID
		this.save()
	}

	// -------------------------------------------------------------------------------- UTILS
	isChapterUnlock (chapterID) {
		for (const chapter of this.chaptersUnlock.value) {
			if (chapterID === chapter) {
				return true
			}
		}
		return false
	}

	isStageUnlock (stageID) {
		for (const stage of this.stagesUnlock.value) {
			if (stage == stageID) {
				return true
			}
		}
		return false
	}
}

export default new UserData()
