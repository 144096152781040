import { createApp } from 'vue'
import Button from '@/components/Button.vue'
import TextKR from '@/components/TextKR.vue'
import BackButton from '@/components/BackButton.vue'
import App from '@/App.vue'

import router from './router'

// Userdata test
// import UserData from './data/UserData'
// console.log(UserData.save())

const app = createApp(App).use(router)
app.component('Button', Button)
app.component('TextKR', TextKR)
app.component('BackButton', BackButton)
app.mount('#app')
