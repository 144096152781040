<template>
	<div class="button" :class="{disabled:disabled}">
		<slot />
		{{ text }}
		<h4 v-if="subtitle!=''">
			{{ subtitle }}
		</h4>
	</div>
</template>

<script>

export default {
	name: 'Button',
	props: {
		text: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="stylus" scoped>
.button
	padding 20px 10px
	width 200px
	margin auto
	background #fff
	margin-bottom 40px
	border-radius: 15px
	cursor pointer
	box-shadow: 0px 10px 12px 4px rgba(0,0,0,0.1)
	user-select none
	&:hover
		background #FAFAFA
	&.disabled
		background #ccc
	h4
		margin-top 10px
		color #333
		font-size 12px
		padding 0
		margin-bottom 0

</style>
