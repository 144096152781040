<template>
	<div @click="onClick" class="back">
		<img ref="img" src="img/ui/icon-back.svg">
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'
import router from '@/router'

export default {
	name: 'BackButton',
	mounted () {
		gsap.set(this.$el, { x: 30 })
		this.show()
	},
	methods: {
		onClick () {
			router.go(-1)
		},
		show () {
			gsap.to(this.$el, { opacity: 1, delay: 0.5, ease: Quad.easeOut, duration: 0.5, x: 0 })
		},
		hide () {
			gsap.to(this.$el, { opacity: 0, ease: Quad.easeOut, duration: 0.5, x: -30 })
		}
	}
}
</script>

<style lang="stylus" scoped>
.back
	color #fff
	opacity 0
	will-change opacity
	width 20px
	height 20px
	cursor pointer
	position absolute
	top 20px
	left 20px
	user-select none
	img
		width 32px
		height 32px
		display inline-block
		vertical-align middle
		will-change opacity

</style>
