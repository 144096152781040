<template>
	<div class="sign" v-if="unsigned" @click="onClick" ref="blur">
		<img src="img/18sign.png">
	</div>
	<div class="center" v-if="shouldShow">
		<video ref="video" loop playsinline @click="next" :src="'/videos/low/'+this.current+'_1.mp4'" />
	</div>
</template>

<script>
import gsap from 'gsap/gsap-core'
export default {
	name: 'App',
	components: {
	},
	data () {
		return {
			unsigned: true,
			shouldShow: false,
			current: Math.floor(1 + Math.random() * 9)
		}
	},
	mounted () {
	},
	methods: {
		onClick () {
			this.shouldShow = true
			setTimeout(() => {
				this.$refs.video.play()
			}, 1)
			gsap.to(this.$refs.blur, {
				scale: 1.1, opacity: 0, duration: 2, onComplete: () => {
					this.unsigned = false
				}
			})
		},
		next () {
			this.current++
			if (this.current > 10) {
				this.current = 1
			}
			this.updateVideo()
		},
		prev () {
			this.current--
			if (this.current < 1) {
				this.current = 10
			}
			this.updateVideo()
		},
		updateVideo () {
			this.$refs.video.src = '/videos/low/' + this.current + '_1.mp4'
			this.$refs.video.play()
		}
	}
}
</script>

<style lang="stylus">
@import "./assets/css/fonts.css"

body, html, #app
	height 100%
	min-height 100%
	margin 0
	padding 0
	overflow-x hidden
html
	width 100%
	height 100%
	min-height 100%
	overflow hidden
	touch-action none // Disable Pinch Zoom
	font-family: 'Roboto', sans-serif
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale
	text-align center
	background #fff
	.sign
		background url('/img/blur.jpg')
		background-repeat: no-repeat
		background-size: cover
		text-align: center
		height 100%
		min-height 100%
		width: 100%
		position absolute
		top 0
		left 0

		img
			max-width calc(100% - 80px)
			position absolute
			top 50%
			left 50%
			transform translate3d(-50%,-50%,0)
			height: auto
	video
		object-fit: contain
		max-height: 100%
		width: auto
	.center
		height 100%
		min-height 100%
		width: 100%

</style>
