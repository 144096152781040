import UserData from '@/data/UserData'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Splash',
		component: () => import(/* webpackChunkName: "splash" */ '@/views/SplashScreen.vue')
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
	},
	{
		path: '/learn',
		name: 'Learn',
		component: () => import(/* webpackChunkName: "learn" */ '@/views/Learn.vue')
	},
	{
		path: '/booking',
		name: 'Booking',
		component: () => import(/* webpackChunkName: "booking" */ '@/views/Booking.vue')
	},
	{
		path: '/invite',
		name: 'Invite',
		component: () => import(/* webpackChunkName: "Invite" */ '@/views/Invite.vue')
	},
	{
		path: '/hangul-river',
		name: 'HangulRiver',
		component: () => import(/* webpackChunkName: "HangulRiver" */ '@/views/HangulRiver.vue')
	},
	{
		path: '/chapter/:id',
		name: 'Chapter',
		component: () => import(/* webpackChunkName: "chapter" */ '@/views/Chapter.vue')
	},
	{
		path: '/stage/hangul/:id',
		name: 'Hangul',
		component: () => import(/* webpackChunkName: "stage-hangul" */ '@/stages/hangul/Hangul.vue')
	},
	{
		path: '/stage/vocabulary/:id',
		name: 'Vocabulary',
		component: () => import(/* webpackChunkName: "stage-vocabulary" */ '@/stages/Stage.vue')
	},
	{
		path: '/stage/challenge/:id',
		name: 'Stage',
		component: () => import(/* webpackChunkName: "stage-challenge" */ '@/stages/Stage.vue')
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "Register" */ '@/views/Register.vue')
	},
	{
		path: '/create-profile',
		name: 'CreateProfile',
		component: () => import(/* webpackChunkName: "CreateProfile" */ '@/views/CreateProfile.vue')
	},
	{
		path: '/setting',
		name: 'Setting',
		component: () => import(/* webpackChunkName: "Setting" */ '@/views/Setting.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

let first = true
router.beforeResolve((to, from, next) => {
	switch (to.name) {
	case 'Profile':
		if (!UserData.isLogin.value) {
			next({ name: 'CreateProfile' })
			return
		}
		break
	case 'CreateProfile':
		console.log(UserData.isLogin)
		if (UserData.isLogin.value) {
			next({ name: 'Profile' })
			return
		}
	}

	if (first) {
		first = false
		next()
		return
	}
	if (from.name == 'Splash' || to.name == 'Splash') {
		if (router.transitionComponent) {
			router.transitionComponent.play(next)
		} else {
			next()
		}
	} else {
		next()
	}
})
export default router
